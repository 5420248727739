
import {
  IonContent,
  IonPage,
  IonButton,
  alertController,
  IonList,
  IonItem,
  IonAvatar,
  IonBadge,
  IonLabel
} from '@ionic/vue';

import {defineComponent} from 'vue';
import api from '@/api/api'
// Import Swiper styles

export default defineComponent({
  name: 'HomePage',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonList,
    IonItem,
    IonAvatar,
    IonBadge,
    IonLabel
  },
  props: {
    file: String
  },
  mounted() {
    this.load();
  },
  data() {

    return {
      loading: false,
      items: [] as Array<any>,
      bought: [] as Array<any>,
      user: ''
    }
  },
  methods: {
    async load() {
      this.loading = true;
      if (this.file) {
        const list = await api.show(this.file);
        this.items = list.map((item:any,pos:number) => {return {...item,pos:pos};}).filter((item: any) => item.user == "");
        this.bought = list.filter((item: any) => item.user != "");
      }
      this.loading = false;
    },
    getType(type: string) {
      switch (type) {
        case 'used':
          return "Von uns";
        case 'buy':
          return "Kaufen";
        case 'idea':
          return "Idee";
      }
      return ""
    },
    async getUser() {
      if (!this.user) {
        const alert = await alertController.create({
          header: 'Bitte gib deinen Namen an, damit wir wissen, wem wir das Geschenk geben müssen',
          buttons: [
            {
              text: 'Ok',
              role: 'ok',
              handler: (alertData: any) => { //takes the data
                this.user = alertData.name
              }
            },
            'Abbrechen'
          ],
          inputs: [
            {
              name: 'name',
              placeholder: 'Name',
              attributes: {
                maxlength: 16,
                minlength: 1
              }
            },
          ],
        });

        await alert.present();
        const {role} = await alert.onDidDismiss();
        return role !== 'ok';
      }
      return false;

    },
    showpic(idx:number){
      this.items[idx].showpic=1;
    },
    async buy(pos:number, idx: number) {
      if (this.file) {
        this.loading = true;
        const item = this.items[idx] as any;
        var user = "anonymous";
        if (item.type == 'used') {
          while (this.user == "") {
            const canceled = await this.getUser();
            user = this.user;
            if (canceled) {
              this.loading = false;
              return;
            }
          }
        }
        await api.update(this.file, pos, user);
        item.user = user;
        this.user = "";
        this.items[idx] = item;
        this.loading = false;
      }
    }
  }
});


import {IonContent, IonPage, IonButton, IonList, IonItem, IonLabel} from '@ionic/vue';

import {defineComponent} from 'vue';

import api from '@/api/api';
import router from "@/router";

export default defineComponent({
  name: 'HomePage',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonList, IonItem, IonLabel
  },

  data() {
    return {
      files: [] as Array<string>
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.files = await api.index();
    },
    goTo(file: string) {
      router.push('/zettel/' + file)
    }
  }
});

class Api {
    fake = false;

    index() {
        if (this.fake) {
            console.log("fake list");
            return Promise.resolve(['Marei', 'Cora']);
        } else {
            return fetch('/api.php?fnc=index')
                .then((response) => response.json())
        }
    }

    show(list: string) {
        if (this.fake) {
            console.log("fake show",list);
            return Promise.resolve([
                {
                    "type": "used",
                    "name": "Blabla",
                    "pic": "icon.png",
                    "user": "",
                    "price": "5"
                },
                {
                    "type": "used",
                    "name": "Blabla",
                    "pic": "icon.png",
                    "user": "",
                    "price": "5"
                },
                {
                    "type": "buy",
                    "name": "Blabla",
                    "pic": "icon.png",
                    "url": "https://www.amazon.de",
                    "user": "",
                    "price": "5"
                },
                {
                    "type": "idea",
                    "name": "Blabla",
                    "pic": "icon.png",
                    "user": "Test"
                }
            ]);
        } else {
            return fetch('/api.php?fnc=show&file=' + list)
                .then((response) => response.json())
        }
    }

    update(list: string, idx: number, name: string) {
        if (this.fake) {
            console.log("fake update",list,idx,name);
            return Promise.resolve({
                updated: idx
            });
        } else {
            return fetch('/api.php?fnc=update&file=' + list + '&item=' + idx + '&user=' + name)
                .then((response) => response.json())
        }
    }
}

export default new Api();